import React, { useEffect, useRef } from "react";
import "./BannerVideoComponent.style.scss";
import { BannerVideoComponentProps } from "./BannerVideoComponent.types";

const BannerVideoComponent: React.FC<BannerVideoComponentProps> = ({
  video = "intro.mov",
  children,
  noMaxHeight,
  header = false,
}) => {
  const refVideo = useRef<HTMLVideoElement>(null);

  /**
   *
   */
  useEffect(() => {
    if (!refVideo.current) return;

    //open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
    refVideo.current.defaultMuted = true;
    refVideo.current.muted = true;
    refVideo.current.play();
  }, []);

  return (
    <div className="banner-video-component">
      {header ? (
        <div className="banner-video-component--video-width-wrapper">
          <video playsInline autoPlay muted loop ref={refVideo}>
            <source src={`/video/${video}`} type="video/mp4" />
          </video>
        </div>
      ) : (
        <div
          className={[
            "banner-video-component--video-wrapper",
            noMaxHeight ? "no-maxed" : undefined,
          ].join(" ")}
        >
          <video playsInline autoPlay muted loop ref={refVideo}>
            <source src={`/video/${video}`} type="video/mp4" />
          </video>
        </div>
      )}
      {!!children ? (
        <div className="banner-video-component--content-wrapper">
          {children}
        </div>
      ) : (
        <div className="banner-video-component--text-wrapper">MedBed</div>
      )}
    </div>
  );
};

export default BannerVideoComponent;
