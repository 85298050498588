import React, { useEffect, useState } from "react";
import { FeedbackComponentProps } from "./FeedbackComponent.types";
import "./FeedbackComponent.styles.scss";
import SeperatorComponent from "../SeperatorComponent/SeperatorComponent";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import {
  getNotListedPlaylist,
  randomizePlaylistItems,
} from "../../utils/Youtube.utils";
import { PlaylistItem } from "../../utils/Youtube.types";

const FeedbackComponent: React.FC<FeedbackComponentProps> = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const [currentPlaylist, setCurrentPlaylist] = useState<PlaylistItem[]>([]);

  /**
   *
   */
  useEffect(() => {
    getNotListedPlaylist(language).then((loadedPlaylist) => {
      setCurrentPlaylist(randomizePlaylistItems(loadedPlaylist));
    });
  }, [language]);

  return (
    <div className="feedback-component-wrapper padding-wrapper-60">
      <h2 className="text-wrapper--h2 center-text white">
        {t("component.feedback.title")}
      </h2>
      <SeperatorComponent height={30} />
      <div
        className="text-wrapper--paragraph white center-text"
        dangerouslySetInnerHTML={{
          __html: t("component.feedback.note"),
        }}
      />
      <SeperatorComponent height={30} />
      <div className="feedback-video-wrapper">
        {currentPlaylist.map((video, videoIndex) => (
          <iframe
            key={`feedback-video-${videoIndex}`}
            className="feedback-video-wrapper-item"
            allowFullScreen
            loading="lazy"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title={video.snippet?.title ?? ""}
            src={`https://www.youtube.com/embed/${video.contentDetails.videoId}?controls=1&rel=0&playsinline=0&modestbranding=0&autoplay=0&enablejsapi=1&origin=https%3A%2F%2Fmedbed.com`}
          />
        ))}
      </div>
      {!!t("component.feedback.link", { defaultValue: "" }) && (
        <div className="feedback-button-wrapper">
          <ButtonComponent
            className="custom-voice-button"
            url="https://www.youtube.com/c/9010TV"
            type="link"
            backgroud="#0b92b4"
            title={t("component.feedback.link")}
          />
        </div>
      )}
    </div>
  );
};

export default FeedbackComponent;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
