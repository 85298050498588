import React, { useState } from "react";
import { CollapseComponentProps } from "./CollapseComponent.types";
import "./CollapseComponent.styles.scss";
import { Collapse } from "react-collapse";
import PlusIcon from "./assets/plus.svg";

const CollapseComponent: React.FC<CollapseComponentProps> = ({
  title,
  content,
}) => {
  const [isOpen, toggleOpen] = useState<boolean>(false);

  return (
    <div className="collapse-component-wrapper">
      <div className="title-wrapper">
        <div
          className="title"
          onClick={() => toggleOpen(!isOpen)}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          className="toggle"
          style={isOpen ? { transform: "rotate(45deg)" } : {}}
        >
          <PlusIcon onClick={() => toggleOpen(!isOpen)} />
        </div>
      </div>
      <Collapse isOpened={isOpen}>
        <div
          className="content-wrapper"
          dangerouslySetInnerHTML={{ __html: `<br/><br/>${content}<br/><br/>` }}
        />
      </Collapse>
    </div>
  );
};

export default CollapseComponent;
