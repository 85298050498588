import Axios from "axios";
import { PlaylistItem, PlaylistResponse } from "./Youtube.types";

/**
 * Helper to fetch current amount of items in youtube playlist
 * @returns found amount or 500
 */
export const getCurrentAmountOfVideosInPlaylist = async (): Promise<number> => {
  const currentApiKey: string = "AIzaSyDZBKOHvkZknPoTt_0oHv8ugj0CCiHHE3U";
  const currentPlaylistIds: string[] = [
    "PLpVBGH7UMulAcARFwoXj5oIGjaLiSN26V",
    "PLpVBGH7UMulB70FC3caz7G-yzy7hRlmoq",
  ];

  let currentAmountOFUnusedVideos: number = 0;
  for (let index = 0; index < currentPlaylistIds.length; index++) {
    const foundTotal: number = await Axios.get(
      "https://www.googleapis.com/youtube/v3/playlistItems",
      {
        params: {
          key: currentApiKey,
          part: "id",
          maxResults: 1,
          playlistId: currentPlaylistIds[index],
        },
      }
    )
      .then((resp) => resp.data.pageInfo.totalResults ?? 0)
      .catch(() => 0);
    currentAmountOFUnusedVideos += foundTotal;
  }
  return currentAmountOFUnusedVideos > 8
    ? Math.floor(currentAmountOFUnusedVideos / 100) * 100
    : currentAmountOFUnusedVideos;
};

/**
 *
 * @param language
 */
export const getCorrectPlaylistForLanguage = (
  language: string = "en"
): string => {
  switch (language) {
    case "fr":
      return "PLpVBGH7UMulBXI5RAjE2XvrRg4Xn0Y7gi";
    case "ja":
      return "PLpVBGH7UMulDWl4b5HIeI0GEcTTbMLEHN";
    case "es":
      return "PLpVBGH7UMulBgk9p4x__Q4Say2A27Z60-";
    case "de":
      return "PLpVBGH7UMulD-59WBu1IzMVcIYnz9--MA";
    case "en":
    default:
      return "PLpVBGH7UMulCFA8daowwYCxi5UcesOXQc";
  }
};

/**
 * API METHOD - to call youtube data api
 * for playlistitems
 *
 * @param language playlist id
 * @returns empty array in case of error or all items
 */
export const getNotListedPlaylist = async (
  language: string
): Promise<PlaylistItem[]> => {
  const currentApiKey: string = "AIzaSyDZBKOHvkZknPoTt_0oHv8ugj0CCiHHE3U";
  return await Axios.get(
    "https://www.googleapis.com/youtube/v3/playlistItems",
    {
      params: {
        key: currentApiKey,
        part: "contentDetails,snippet",
        maxResults: 50,
        playlistId: getCorrectPlaylistForLanguage(language),
      },
    }
  )
    .then((resp) => (resp.data as PlaylistResponse).items ?? [])
    .catch(() => []);
};

/**
 * Helper to randomize and limit array of {@link PlaylistItem}s
 *
 * @param {array} playlistItems items to randomize
 * @param {number} maxVideos optional parameter to limit response
 * @returns {array} shuffeld and limited array
 */
export const randomizePlaylistItems = (
  playlistItems: PlaylistItem[],
  maxVideos: number = 20
): PlaylistItem[] => {
  playlistItems = shuffle(playlistItems);
  if (playlistItems.length > maxVideos)
    return playlistItems.splice(0, maxVideos);
  return playlistItems;
};

/**
 * Shuffles array in place.
 *
 * @param {array} arrayToShuffle items An array containing the items.
 * @return {array} shuffeld array
 */
const shuffle = (arrayToShuffle: PlaylistItem[]): PlaylistItem[] => {
  for (let i = arrayToShuffle.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arrayToShuffle[i], arrayToShuffle[j]] = [
      arrayToShuffle[j],
      arrayToShuffle[i],
    ];
  }
  return arrayToShuffle;
};
