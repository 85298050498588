import React from "react";
import CollapseComponent from "../CollapseComponent/CollapseComponent";
import SeperatorComponent from "../SeperatorComponent/SeperatorComponent";
import "./FaqComponent.styles.scss";
import { FaqComponentProps } from "./FaqComponent.types";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const FaqComponent: React.FC<FaqComponentProps> = () => {
  const { t } = useTranslation();

  const contentFaq: { content: string; title: string }[] = t(
    "component.faq.content",
    { returnObjects: true }
  );

  return (
    <div className="faq-component-wrapper padding-wrapper-60">
      <div className="max-width-wrapper">
        <h2
          className="text-wrapper--h2 white"
          dangerouslySetInnerHTML={{
            __html: t("component.faq.title"),
          }}
        />
        <SeperatorComponent />
        {contentFaq.map((faq, faqIndex) => (
          <CollapseComponent
            key={`faq-entry-${faqIndex}`}
            title={faq.title}
            content={faq.content}
          />
        ))}
      </div>
    </div>
  );
};

export default FaqComponent;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
