import React, { useState, useEffect } from "react";
import { YouTubeComponentProps } from "./YouTubeComponent.types";
import "./YouTubeComponent.style.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { getCurrentAmountOfVideosInPlaylist } from "../../utils/Youtube.utils";
import SeperatorComponent from "../SeperatorComponent/SeperatorComponent";
import { Waypoint } from "react-waypoint";
import { CountUp } from "use-count-up";

const YouTubeComponent: React.FC<YouTubeComponentProps> = () => {
  const { t } = useTranslation();
  const [currentVideoAmount, setCurrentVideoAmount] = useState<number>(500);
  const [startMedBed, setStartMedBed] = useState<boolean>(false);
  const [startUser, setStartUser] = useState<boolean>(false);
  const [startVideo, setStartVideo] = useState<boolean>(false);
  const [showMedBed, setShowMedBed] = useState<boolean>(false);
  const [showUser, setShowUser] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);

  /**
   * this useEffect fetches the current youtube amount
   */
  useEffect(() => {
    getCurrentAmountOfVideosInPlaylist().then(setCurrentVideoAmount);
  }, []);

  /**
   * Helper to generate correct elemnts to be displayed
   * @param value current vlaue to display
   * @returns generated elements
   */
  const convertNumberToElements = (value: number): JSX.Element[] => {
    let returnArray: JSX.Element[] = [];
    let localValue: string = value.toFixed(0);
    let localSplittedValue = localValue.split("");
    for (let index = 0; index < localSplittedValue.length; index++) {
      returnArray.push(
        <span className="intern-char">{localSplittedValue[index]}</span>
      );
    }
    return returnArray;
  };

  return (
    <div className="youtube-component-wrapper">
      <div className="title">{t("component.youtube.title")}</div>
      <div className="content">
        <div className="content--item">
          <div className="content--item--title">
            <Waypoint
              onEnter={() => {
                setStartMedBed(true);
                setShowMedBed(false);
              }}
              onLeave={() => setStartMedBed(false)}
            >
              <span className="char-wrapper">
                <CountUp
                  key={startMedBed ? "show-medbed" : "dont-show-medbed"}
                  onComplete={() => setShowMedBed(true)}
                  isCounting={startMedBed}
                  duration={3}
                  end={120}
                  formatter={convertNumberToElements}
                />
              </span>
            </Waypoint>
            <div className="plus-wrapper">
              <span className="invisible">+</span>
              <span
                style={
                  showMedBed ? { display: "initial" } : { display: "none" }
                }
                className="fade-in-text"
              >
                +
              </span>
            </div>
          </div>
          <div
            className="content--item--subnote"
            dangerouslySetInnerHTML={{
              __html: t("component.youtube.subMedBed"),
            }}
          />
        </div>
        <SeperatorComponent height={30} />
        <div className="content--item">
          <div className="content--item--title">
            <Waypoint
              onEnter={() => {
                setStartUser(true);
                setShowUser(false);
              }}
              onLeave={() => setStartUser(false)}
            >
              <span className="char-wrapper">
                <CountUp
                  key={startUser ? "show-user" : "dont-show-user"}
                  onComplete={() => setShowUser(true)}
                  isCounting={startUser}
                  duration={8}
                  end={33000}
                  formatter={convertNumberToElements}
                />
              </span>
            </Waypoint>
            <div className="plus-wrapper">
              <span className="invisible">+</span>
              <span
                style={showUser ? { display: "initial" } : { display: "none" }}
                className="fade-in-text"
              >
                +
              </span>
            </div>
          </div>
          <div
            className="content--item--subnote"
            dangerouslySetInnerHTML={{
              __html: t("component.youtube.subUsers"),
            }}
          />
        </div>
        <SeperatorComponent height={30} />
        <div className="content--item">
          <div className="content--item--title">
            <Waypoint
              onEnter={() => {
                setStartVideo(true);
                setShowVideo(false);
              }}
              onLeave={() => setStartVideo(false)}
            >
              <span className="char-wrapper">
                <CountUp
                  key={startVideo ? "show-video" : "dont-show-video"}
                  onComplete={() => setShowVideo(true)}
                  isCounting={startVideo}
                  duration={5}
                  end={currentVideoAmount}
                  formatter={convertNumberToElements}
                />
              </span>
            </Waypoint>
            <div className="plus-wrapper">
              <span className="invisible">+</span>
              <span
                style={showVideo ? { display: "initial" } : { display: "none" }}
                className="fade-in-text right-10"
              >
                +
              </span>
            </div>
          </div>
          <div
            className="content--item--subnote"
            dangerouslySetInnerHTML={{
              __html: t("component.youtube.subVideo"),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default YouTubeComponent;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
