import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState } from "react";
import "./BookPopupComponent.styles.scss";
import { useTranslation } from "react-i18next";
import { BookPopupComponentProps } from "./BookPopupComponent.types";
import ReactPlayer from "react-player";
import PlayIcon from "../../assets/svg/play.svg";
import PauseIcon from "../../assets/svg/pause.svg";
import BackwardIcon from "../../assets/svg/backward.svg";
import { useI18next } from "gatsby-plugin-react-i18next";

const BookPopupComponent: React.FC<BookPopupComponentProps> = ({
  open,
  type,
  onClose,
}) => {
  const { t } = useTranslation();
  const [play, setPlay] = useState<boolean>(false);
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const playerRef = useRef<ReactPlayer>(null);
  const { language } = useI18next();

  /**
   * this useEffect sets the current seek time to 0
   */
  useEffect(() => {
    if (!playerRef || !open) return;
    setProgressPercent(0);
    playerRef.current?.seekTo(0);
  }, [playerRef, open]);

  /**
   * Helper to generate correct download pdf for reading example
   * @returns correct file name
   */
  const getCorrectReadExample = (): string => {
    switch (language) {
      case "de":
        return "Leseprobe_de.pdf";
      case "es":
      case "fr":
      case "ja":
      case "en":
      default:
        return "Leseprobe_en.pdf";
    }
  };

  /**
   * Helper to generate correct audo file name  example
   * @returns correct file name
   */
  const getCorrectAudioExample = (): string => {
    switch (language) {
      case "de":
        return "The_MedBed_effect_DE.mp3";
      case "es":
      case "fr":
      case "ja":
      case "en":
      default:
        return "The_MedBed_effect_EN.mp3";
    }
  };

  return (
    <div
      className="book-popup-component"
      onClick={onClose}
      style={open ? { display: "flex" } : { display: "none" }}
    >
      <div
        className="book-popup-component--wrapper"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div className="image--wrapper">
          <div className="bg-fond" />
          <StaticImage
            className="center-items"
            src="../../assets/images/90.10.Soft-Cover.png"
            alt="MedBed"
            placeholder="blurred"
          />
        </div>
        <div className="content--wrapper">
          <div onClick={onClose} className="close-button">
            X
          </div>
          {type === "audio" ? (
            <div className="audio-wrapper">
              <div className="text">
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("bookPopup.title"),
                  }}
                />
                <br />
                {t("bookPopup.listen")}
              </div>
              <div>
                <StaticImage
                  src="../../assets/images/PLAYER.png"
                  alt="Audio Player"
                  placeholder="blurred"
                  layout="fullWidth"
                />
                <ReactPlayer
                  ref={playerRef}
                  playing={play}
                  url={`/audio/${getCorrectAudioExample()}`}
                  height={0}
                  width={0}
                  onProgress={(state) => {
                    setProgressPercent(state.played);
                  }}
                />
                <div className="control-wrapper">
                  <div className="status">
                    <div className="background" />
                    <div
                      className="thick-background"
                      style={{ width: `${progressPercent * 100}%` }}
                    />
                    <div
                      className="dot"
                      style={{ left: `${progressPercent * 100}%` }}
                    />
                  </div>
                  <div className="controls">
                    <div
                      onClick={() => {
                        if (progressPercent === 0) return;
                        setProgressPercent(0);
                        playerRef.current?.seekTo(0);
                      }}
                      className={[
                        "backward",
                        progressPercent === 0 ? "light-gray" : undefined,
                      ].join(" ")}
                    >
                      <BackwardIcon />
                    </div>
                    <div className="play-pause" onClick={() => setPlay(!play)}>
                      {play ? <PauseIcon /> : <PlayIcon />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="download-wrapper">
              <div className="text">
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("bookPopup.title"),
                  }}
                />
                <br />
                {t("bookPopup.read")}
              </div>
              <a href={`/text/${getCorrectReadExample()}`} target="_blank">
                <StaticImage
                  src="../../assets/images/download.png"
                  alt="Download"
                  placeholder="blurred"
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookPopupComponent;
