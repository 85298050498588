import React from "react";
import { ButtonComponentProps } from "./ButtonComponent.types";
import "./ButtonComponent.style.scss";

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  title,
  type = "button",
  url = "/",
  fullWidth = false,
  backgroud = "#d3b51c",
  color = "white",
  onClick,
  className,
}) => {
  return (
    <div className="button-component-wrapper">
      <div
        onClick={onClick}
        className={[
          "button-component-wrapper--button-wrapper",
          fullWidth ? "full-width" : undefined,
          className ? className : undefined,
        ].join(" ")}
      >
        {type === "link" ? (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: color,
              backgroundColor: backgroud,
            }}
          >
            {title}
          </a>
        ) : (
          <button
            type={type}
            style={{
              color: color,
              backgroundColor: backgroud,
            }}
          >
            {title}
          </button>
        )}
      </div>
    </div>
  );
};

export default ButtonComponent;
