import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState } from "react";
import "./ImageSliderComponent.styles.scss";
import { ImageSliderComponentProps } from "./ImageSliderComponent.types";
import Draggable, { ControlPosition } from "react-draggable";
import { useWindowSize } from "../../utils/useWindowSize";

const ImageSliderComponent: React.FC<ImageSliderComponentProps> = ({}) => {
  const { width } = useWindowSize();
  const [currentXPostition, setCurrentXPostition] = useState<number>(width / 2);
  const sliderRef = useRef<HTMLDivElement>(null);
  const [currentDragPosition, setCurrentDragPosition] =
    useState<ControlPosition>({ x: width / 2, y: 0 });
  const [arrowDistance, setArrowDistance] = useState<number>(0);
  const sliderWidth: number = 4;

  /**
   * Helper to set middle point if the page is resized
   */
  useEffect(() => {
    if (width / 2 === currentXPostition) return;
    setCurrentDragPosition({ x: width / 2, y: 0 });
    setCurrentXPostition(width / 2);
  }, [width]);

  /**
   * helper to handle the drag of the line
   */
  const handleDrag = (): void => {
    if (!sliderRef) return;
    const localCurrentXPosition: number =
      sliderRef.current?.getBoundingClientRect().x ?? width / 2;
    setCurrentXPostition(localCurrentXPosition);
  };

  return (
    <div className="image-slider-component-wrapper">
      <StaticImage
        className="slider-image-for-height"
        src="./assets/medbed_world.jpeg"
        alt="MedBed World"
        placeholder="blurred"
      />
      <div className="slider-content" key={`${width}-${currentDragPosition.x}`}>
        <Draggable
          axis="x"
          bounds="parent"
          onDrag={handleDrag}
          defaultPosition={currentDragPosition}
        >
          <div
            onMouseEnter={() => setArrowDistance(8)}
            onMouseLeave={() => setArrowDistance(0)}
            ref={sliderRef}
            className="slider-control"
            style={{ width: `${sliderWidth}px` }}
          >
            <div
              className="arrow left"
              style={{ left: `-${sliderWidth + 7 + arrowDistance}px` }}
            />
            <div
              className="arrow right"
              style={{ left: `${sliderWidth + arrowDistance}px` }}
            />
          </div>
        </Draggable>

        <div
          className="slider-image-wrapper"
          style={{ width: `${currentXPostition}px` }}
        >
          <StaticImage
            style={{ zIndex: 1 }}
            className="slider-image"
            src="./assets/medbed_world.jpeg"
            alt="MedBed World"
            placeholder="blurred"
          />
        </div>
        <div
          className="slider-image-wrapper"
          style={{
            left: `${currentXPostition}px`,
            width: `${width - currentXPostition}px`,
          }}
        >
          <StaticImage
            style={{ zIndex: 2, left: `-${currentXPostition}px` }}
            className="slider-image"
            src="./assets/real_world.jpeg"
            alt="Real World"
            placeholder="blurred"
          />
        </div>
      </div>
    </div>
  );
};

export default ImageSliderComponent;
