import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useRef } from "react";
import useState from "react-usestateref";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import ContentRowComponent from "../ContentRowComponent/ContentRowComponent";
import { AppComponentProps } from "./AppComponent.types";
import "./AppComponent.styles.scss";
import { useWindowSize } from "../../utils/useWindowSize";
// images
import parallaxBGDE from "../../assets/images/app/iPhone_Academy_DE.png";
import parallaxBGEN from "../../assets/images/app/iPhone_Academy_EN.png";
import parallaxFrontDE from "../../assets/images/app/iPhone_RECHTS_Farbe_3.png";
import parallaxFrontEN from "../../assets/images/app/iPhone_RECHTS_Farbe_4_EN.png";
import { useTranslation } from "react-i18next";
import { useI18next } from "gatsby-plugin-react-i18next";

const AppComponent: React.FC<AppComponentProps> = ({}) => {
  const { language } = useI18next();
  const divRef = useRef<HTMLDivElement>(null);
  const divImageRef = useRef<HTMLDivElement>(null);
  const rowRef = useRef<HTMLDivElement>(null);
  const [scrollDistanceToTop, setScrollDistanceToTop] = useState<number>(0);
  const { width, height } = useWindowSize();
  const [, setLocalHeight, refLocalHeight] = useState<number>(0);
  const { t } = useTranslation();

  /**
   * Helper to get correct parallax image for language
   * @returns correct image url
   */
  const generateCorrectParallaxImage = (): string => {
    switch (language) {
      case "de":
        return parallaxBGDE;
      case "es":
      case "fr":
      case "ja":
      case "en":
      default:
        return parallaxBGEN;
    }
  };

  /**
   * Helper to get correct parallax front image for language
   * @returns correct image url
   */
  const generateCorrecFrontImage = (): string => {
    switch (language) {
      case "de":
        return parallaxFrontDE;
      case "es":
      case "fr":
      case "ja":
      case "en":
      default:
        return parallaxFrontEN;
    }
  };

  /**
   * Helper to get correct book cover for language
   * @returns correct image url
   */
  const generateCorrectStockImage = (): JSX.Element => {
    switch (language) {
      case "de":
        return (
          <StaticImage
            src="../../assets/images/app/iPhone_LINKS_frei_4_DE.png"
            alt="Mockup iPhones"
            placeholder="blurred"
          />
        );
      case "es":
      case "fr":
      case "ja":
      case "en":
      default:
        return (
          <StaticImage
            src="../../assets/images/app/iPhone_LINKS_frei_4_EN.png"
            alt="Mockup iPhones"
            placeholder="blurred"
          />
        );
    }
  };

  /**
   * Helper to get correct book cover for language
   * @returns correct image url
   */
  const generateCorrectMockImage = (): JSX.Element => {
    switch (language) {
      case "de":
        return (
          <StaticImage
            src="../../assets/images/app/mock_full.png"
            alt="Mockup iPhones"
            layout="fullWidth"
            placeholder="blurred"
          />
        );
      case "es":
      case "fr":
      case "ja":
      case "en":
      default:
        return (
          <StaticImage
            src="../../assets/images/app/iPhone_Start_EN.png"
            alt="Mockup iPhones"
            layout="fullWidth"
            placeholder="blurred"
          />
        );
    }
  };

  /**
   * Helper to handle the scrolling for the images
   */
  const handleScroll = (): void => {
    if (refLocalHeight.current === 0) return;
    const scrollStopSwitch: number = 0;
    let scrollStartSwitch: number = Math.round(refLocalHeight.current / 4);

    let isDivVisible: number = divRef.current?.getBoundingClientRect().top ?? 1;
    let imgHeight: number = divRef.current?.getBoundingClientRect().height ?? 1;
    let currentDistance: number = Math.round(
      scrollStartSwitch - scrollStopSwitch - isDivVisible
    );
    // how many percent of the image should be shown
    let maxDistance: number = imgHeight * 0.275;
    let scrollDistance: number = currentDistance * -1;

    switch (true) {
      case currentDistance < 0:
        setScrollDistanceToTop(0);
        break;
      case currentDistance <= maxDistance:
        setScrollDistanceToTop(scrollDistance);
        break;
      case currentDistance > maxDistance:
        setScrollDistanceToTop(maxDistance * -1);
        break;
      default:
        break;
    }
  };

  /**
   * this useEffect handle the window scroll event listener
   */
  useEffect(() => {
    let currentLayout = document.getElementById("layout-content");
    if (!currentLayout) return;
    currentLayout.addEventListener("scroll", handleScroll, true);

    handleScroll();

    return () => {
      if (!currentLayout) return;
      currentLayout.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /**
   * this useEffect updates the scrolling
   * if height or width changes
   */
  useEffect(() => {
    setLocalHeight(height);

    if (!divImageRef.current) return;
    if (!rowRef.current) return;
    handleScroll();
  }, [divImageRef, width, height, rowRef]);

  return (
    <ContentRowComponent
      maxWidthWrapper
      maxWidth={1920}
      className="content-row-margin-bottom-app"
    >
      <div className="center-text rel-object global-app-row" ref={rowRef}>
        <div className="bg-color-strip" />
        <div className="text-wrapper--h2">{t("app.title")}</div>
        <div className="text-wrapper--subtitle">{t("app.subTitle")}</div>
        <ButtonComponent
          title="App downloaden"
          className="mobile-button"
          backgroud="#21a2c1"
          type="link"
          url="https://app.9010.com"
        />

        <div className="mock-wrapper">
          <div
            className="mock-wrapper--item hide-on-mobile"
            style={{ width: "70%", marginRight: "95px" }}
          >
            {generateCorrectMockImage()}
          </div>
          <div className="mock-wrapper--item special-margins">
            <ButtonComponent
              title={t("app.download")}
              className="desktop-button"
              backgroud="#21a2c1"
              type="link"
              url="https://app.9010.com"
            />
            <div className="double-mock-wrapper" ref={divRef}>
              <div className="double-mock-wrapper--item">
                {generateCorrectStockImage()}
              </div>
              <div className="double-mock-wrapper--item">
                <div
                  style={{
                    backgroundImage: `url(${generateCorrectParallaxImage()})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionY: `${scrollDistanceToTop}px`,
                    backgroundSize: "100% 100%",
                  }}
                  ref={divImageRef}
                >
                  <img
                    alt="Front App Image"
                    src={generateCorrecFrontImage()}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentRowComponent>
  );
};

export default AppComponent;
