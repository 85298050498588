import React, { useState } from "react";
import { graphql } from "gatsby";
import BannerVideoComponent from "../components/BannerVideoComponent/BannerVideoComponent";
import ContentRowComponent from "../components/ContentRowComponent/ContentRowComponent";
import LayoutComponent from "../components/LayoutComponent/LayoutComponent";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/index.styles.scss";
import SeperatorComponent from "../components/SeperatorComponent/SeperatorComponent";
import ButtonComponent from "../components/ButtonComponent/ButtonComponent";
import ImageSliderComponent from "../components/ImageSliderComponent/ImageSliderComponent";
import FeedbackComponent from "../components/FeedbackComponent/FeedbackComponent";
import FaqComponent from "../components/FaqComponent/FaqComponent";
import { Page } from "../components/MenuComponent/MenuComponent.types";
import YouTubeComponent from "../components/YoutubeComponent/YouTubeComponent";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import AppComponent from "../components/AppComponent/AppComponent";
import BookPopupComponent from "../components/BookPopupComponent/BookPopupComponent";
import { useWindowSize } from "../utils/useWindowSize";
import { useInviteCode } from "../utils/useInviteCode";
// DE CALENDLY
import CalendlyDeImage from "../assets/images/calendly/de.png";
import CalendlyDePhoneImage from "../assets/images/calendly/de-phone.png";
import CalendlyDeWebImage from "../assets/images/calendly/de-web.png";
// EN CALENDLY
import CalendlyEnImage from "../assets/images/calendly/en.png";
import CalendlyEnPhoneImage from "../assets/images/calendly/en-phone.png";
import CalendlyEnWebImage from "../assets/images/calendly/en-web.png";
// ES CALENDLY
import CalendlyEsImage from "../assets/images/calendly/es.png";
import CalendlyEsPhoneImage from "../assets/images/calendly/es-phone.png";
import CalendlyEsWebImage from "../assets/images/calendly/es-web.png";
// FR CALENDLY
import CalendlyFrImage from "../assets/images/calendly/fr.png";
import CalendlyFrPhoneImage from "../assets/images/calendly/fr-phone.png";
import CalendlyFrWebImage from "../assets/images/calendly/fr-web.png";
// JP CALENDLY
import CalendlyJpImage from "../assets/images/calendly/jp.png";
import CalendlyJpPhoneImage from "../assets/images/calendly/jp-phone.png";
import CalendlyJpWebImage from "../assets/images/calendly/jp-web.png";

const IndexPage: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const inviteCode = useInviteCode();
  const [typeOfPopup, setTypeOfPopup] = useState<"audio" | "download">();
  const { width } = useWindowSize();

  /**
   * Helper to generate correct calendly image for langaugae
   * @returns correct image
   */
  const generateCalendlyImage = (): string => {
    switch (language) {
      case "de":
        if (width >= 769) return CalendlyDeWebImage;
        if (width < 769 && width > 601) return CalendlyDeImage;
        else return CalendlyDePhoneImage;
      case "es":
        if (width >= 769) return CalendlyEsWebImage;
        if (width < 769 && width > 601) return CalendlyEsImage;
        else return CalendlyEsPhoneImage;
      case "fr":
        if (width >= 769) return CalendlyFrWebImage;
        if (width < 769 && width > 601) return CalendlyFrImage;
        else return CalendlyFrPhoneImage;
      case "ja":
        if (width >= 769) return CalendlyJpWebImage;
        if (width < 769 && width > 601) return CalendlyJpImage;
        else return CalendlyJpPhoneImage;
      case "en":
      default:
        if (width >= 769) return CalendlyEnWebImage;
        if (width < 769 && width > 601) return CalendlyEnImage;
        else return CalendlyEnPhoneImage;
    }
  };

  /**
   * Helper to get correct book cover for language
   * @returns correct image
   */
  const generateCorrectBookImage = (): JSX.Element => {
    switch (language) {
      case "de":
        return (
          <StaticImage
            className="center-items"
            src={`../assets/images/90.10.Soft-Cover_DE.png`}
            alt="MedBed"
            placeholder="blurred"
          />
        );
      case "es":
      case "fr":
      case "ja":
      case "en":
      default:
        return (
          <StaticImage
            className="center-items"
            src={`../assets/images/90.10.Soft-Cover_EN.png`}
            alt="MedBed"
            placeholder="blurred"
          />
        );
    }
  };

  return (
    <>
      <BookPopupComponent
        open={!!typeOfPopup}
        type={typeOfPopup}
        onClose={() => setTypeOfPopup(undefined)}
      />

      <LayoutComponent page={Page.HOME} showHelp>
        <BannerVideoComponent header />
        <ContentRowComponent maxWidthWrapper>
          <div className="center-text rel-object global-intro-book-row">
            <div className="bg-color-strip" />
            <SeperatorComponent height={60} browserOnly />
            <div className="text-wrapper--h2">{t("home.book.title")}</div>
            <div className="text-wrapper--subtitle">
              {t("home.book.subTitle")}
            </div>
            <div className="intro-book-row">
              {generateCorrectBookImage()}
              <div className="internal-text-book-wrapper">
                <div
                  className="text-wrapper--paragraph left heading"
                  dangerouslySetInnerHTML={{
                    __html: t("home.book.subSubTitle"),
                  }}
                />
                <div className="text-wrapper--paragraph left">
                  {t("home.book.text")}
                </div>
                <div className="complete-button-wrapper">
                  <ButtonComponent
                    backgroud="#C1007E"
                    fullWidth
                    title={t("home.book.preOrderButton")}
                    url={t("home.book.link")}
                    type="link"
                  />
                  <div className="button-taste-wrapper">
                    <ButtonComponent
                      backgroud="#21A2C1"
                      title={t("home.book.read")}
                      fullWidth
                      onClick={() => setTypeOfPopup("download")}
                    />
                    <ButtonComponent
                      backgroud="#21A2C1"
                      title={t("home.book.listen")}
                      fullWidth
                      onClick={() => setTypeOfPopup("audio")}
                    />
                  </div>
                  <ButtonComponent
                    backgroud="#F89B06"
                    fullWidth
                    title={t("home.book.audiobookTitle")}
                    url={t("home.book.audiobookLink")}
                    type="link"
                  />
                </div>
              </div>
            </div>
          </div>
        </ContentRowComponent>
        <SeperatorComponent height={60} showBelow={1200} />
        <SeperatorComponent height={20} browserOnly />
        <ContentRowComponent
          animation
          maxWidthWrapper
          itemConfig={[{ width: 44 }, { width: 56 }]}
          order={{ mobile: [2, 1] }}
        >
          <div className="padding-wrapper-120 no-right no-top">
            <h2
              className="text-wrapper--h2"
              dangerouslySetInnerHTML={{
                __html: t("home.boxOne.title"),
              }}
            />
            <SeperatorComponent height={20} />
            <div
              className="text-wrapper--paragraph"
              dangerouslySetInnerHTML={{
                __html: t("home.boxOne.content"),
              }}
            />
          </div>
          <StaticImage
            className="center-items"
            src="../assets/images/lab_scan_fem_nobg_frei.png"
            alt="MedBed"
            placeholder="blurred"
          />
        </ContentRowComponent>
        <SeperatorComponent height={60} showBelow={1200} />
        <ContentRowComponent
          animation
          animationRight
          order={{ mobile: [1, 2] }}
          itemConfig={[{ width: 59 }, { width: 41 }]}
          maxWidthWrapper
        >
          <StaticImage
            className="center-items"
            src="../assets/images/energy.jpeg"
            alt="Energiebild"
            placeholder="blurred"
          />
          <div className="padding-wrapper-120 no-left no-top">
            <h2
              className="text-wrapper--h2"
              dangerouslySetInnerHTML={{
                __html: t("home.boxTwo.title"),
              }}
            />
            <SeperatorComponent height={20} />
            <div
              className="text-wrapper--paragraph"
              dangerouslySetInnerHTML={{
                __html: t("home.boxTwo.content"),
              }}
            />
          </div>
        </ContentRowComponent>
        <SeperatorComponent hideBelow={1200} />
        <SeperatorComponent mobileOnly />
        <div className="embed-container maxed">
          <iframe
            loading="lazy"
            className="vimeo-video-iframe"
            title="Oliver Schacke"
            allowFullScreen
            src={t("home.introVideo")}
          />
        </div>
        <SeperatorComponent height={100} hideBelow={1020} />
        <BannerVideoComponent video="particles.mov" noMaxHeight>
          <div className="center-text maxed-100">
            <SeperatorComponent height={80} />
            <h2
              className="text-wrapper--h2"
              dangerouslySetInnerHTML={{
                __html: t("home.boxThree.title"),
              }}
            />
            <SeperatorComponent height={30} />
            <div
              className="text-wrapper--paragraph padding-left-and-right"
              dangerouslySetInnerHTML={{
                __html: t("home.boxThree.content"),
              }}
            />
            <SeperatorComponent height={60} />
            <ButtonComponent
              className="custom-voice-button"
              backgroud="#F89B06"
              url={t("home.testButton.link", {
                replace: { inviteCode: inviteCode },
              })}
              type="link"
              title={t("home.testButton.text")}
            />
            <SeperatorComponent height={80} />
          </div>
        </BannerVideoComponent>
        <AppComponent />
        <ContentRowComponent
          animation
          maxWidthWrapper
          itemClassName="second-big-img"
        >
          <div className="padding-wrapper-120 no-right">
            <h2
              className="text-wrapper--h2 no-space"
              dangerouslySetInnerHTML={{
                __html: t("home.boxFour.title"),
              }}
            />
            <SeperatorComponent height={20} />
            <div
              className="text-wrapper--paragraph"
              dangerouslySetInnerHTML={{
                __html: t("home.boxFour.content"),
              }}
            />
          </div>
          <StaticImage
            className="center-items"
            src="../assets/images/lab_cube_0000_soft.jpg"
            alt="Cube"
            placeholder="blurred"
          />
        </ContentRowComponent>
        <h2
          className="text-wrapper--h2 center-text"
          dangerouslySetInnerHTML={{
            __html: t("home.video.title"),
          }}
        />
        <SeperatorComponent />
        <div className="embed-container maxed">
          <iframe
            loading="lazy"
            className="vimeo-video-iframe"
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title={t("home.video.description")}
            src={t("home.video.link")}
          />
        </div>
        <SeperatorComponent height={120} hideBelow={1020} />
        <ContentRowComponent
          animation
          className="center-items"
          background="#E1E9F2"
          maxWidthWrapper
        >
          <div className="center-text maxed-61">
            <SeperatorComponent height={100} />
            <h2
              className="text-wrapper--h2"
              dangerouslySetInnerHTML={{
                __html: t("home.boxFive.title"),
              }}
            />
            <SeperatorComponent height={20} />
            <div
              className="text-wrapper--paragraph"
              dangerouslySetInnerHTML={{
                __html: t("home.boxFive.content"),
              }}
            />
            <SeperatorComponent height={100} />
          </div>
        </ContentRowComponent>
        <SeperatorComponent height={100} />
        <ContentRowComponent animation maxWidthWrapper animationRight>
          <StaticImage
            className="center-items"
            src="../assets/images/medbed_2_2.png"
            alt="MedBed"
            placeholder="blurred"
          />
          <div className="padding-wrapper-120 hide-distance-50 no-right little-left">
            <h2
              className="text-wrapper--h2"
              dangerouslySetInnerHTML={{
                __html: t("home.boxSix.title"),
              }}
            />
            <SeperatorComponent height={20} />
            <div
              className="text-wrapper--paragraph"
              dangerouslySetInnerHTML={{
                __html: t("home.boxSix.content"),
              }}
            />
          </div>
        </ContentRowComponent>
        <ContentRowComponent
          animation
          order={{ mobile: [2, 1] }}
          maxWidthWrapper
          itemClassName="second-img-got-padding"
        >
          <div className="padding-wrapper-120 hide-distance-0">
            <h2
              className="text-wrapper--h2"
              dangerouslySetInnerHTML={{
                __html: t("home.boxSeven.title"),
              }}
            />
            <SeperatorComponent height={20} />
            <div
              className="text-wrapper--paragraph a-liitle-bit-right"
              dangerouslySetInnerHTML={{
                __html: t("home.boxSeven.content"),
              }}
            />
          </div>
          <StaticImage
            className="center-items"
            src="../assets/images/medbed_3.jpeg"
            alt="MedBed"
            placeholder="blurred"
          />
        </ContentRowComponent>
        <SeperatorComponent height={100} hideBelow={1201} />
        <SeperatorComponent height={100} mobileOnly />
        <ContentRowComponent
          animation
          className="center-items"
          background=" #1B1B2B"
          maxWidthWrapper
        >
          <div className="center-text maxed-50">
            <SeperatorComponent height={100} />
            <h2
              className="text-wrapper--h2 white"
              dangerouslySetInnerHTML={{
                __html: t("home.boxEight.title"),
              }}
            />
            <SeperatorComponent height={30} />
            <div
              className="text-wrapper--paragraph white"
              dangerouslySetInnerHTML={{
                __html: t("home.boxEight.content"),
              }}
            />
            <SeperatorComponent height={60} />
            <ButtonComponent
              className="custom-voice-button"
              backgroud="#F89B06"
              url={t("home.testButton.link", {
                replace: { inviteCode: inviteCode },
              })}
              type="link"
              title={t("home.testButton.text")}
            />
            <SeperatorComponent height={100} />
          </div>
        </ContentRowComponent>
        <ImageSliderComponent />
        <ContentRowComponent animation className="center-items" maxWidthWrapper>
          <div className="center-text maxed-50" style={{ maxWidth: "69%" }}>
            <SeperatorComponent height={100} />
            <h2
              className="text-wrapper--h2"
              dangerouslySetInnerHTML={{
                __html: t("home.boxTen.title"),
              }}
            />
            <SeperatorComponent height={20} />
            <div
              className="text-wrapper--paragraph"
              dangerouslySetInnerHTML={{
                __html: t("home.boxTen.content"),
              }}
            />
            <SeperatorComponent height={100} />
          </div>
        </ContentRowComponent>
        <SeperatorComponent
          background=" #1B1B2B"
          height={140}
          hideBelow={1201}
        />
        <ContentRowComponent
          background=" #1B1B2B"
          maxWidthWrapper
          className="hide-background-on-column"
          itemConfig={[{ background: "#eeeded", width: 50 }]}
        >
          <div className="padding-wrapper-120 mobile-bed-description">
            <h2
              className="text-wrapper--h2"
              dangerouslySetInnerHTML={{
                __html: t("home.boxEleven.title"),
              }}
            />
            <SeperatorComponent height={20} />
            <div
              className="text-wrapper--paragraph"
              dangerouslySetInnerHTML={{
                __html: t("home.boxEleven.content"),
              }}
            />
          </div>
          <StaticImage
            className="center-items full-height mobile-full-width"
            src="../assets/images/bed_front.jpeg"
            alt="MedBed Front"
            placeholder="blurred"
          />
        </ContentRowComponent>
        <SeperatorComponent
          background=" #1B1B2B"
          height={140}
          hideBelow={1201}
        />
        <ContentRowComponent
          background=" #1B1B2B"
          itemConfig={[
            { background: "transparent", width: 50 },
            { background: "#eeeded", width: 50 },
          ]}
          itemClassName="fixed-50 second-left-assigned"
          maxWidthWrapper
          order={{ mobile: [2, 1] }}
        >
          <StaticImage
            className="center-items full-height mobile-full-width"
            src="../assets/images/bedroom.jpeg"
            alt="MedBed Front"
            placeholder="blurred"
          />
          <div
            className="padding-wrapper-120 mobile-bed-description brown-max-1201"
            style={{ paddingTop: "100px", paddingBottom: "100px" }}
          >
            <h2
              className="text-wrapper--h2"
              dangerouslySetInnerHTML={{
                __html: t("home.boxTwelve.title"),
              }}
            />
            <SeperatorComponent height={20} />
            <div
              className="text-wrapper--paragraph"
              dangerouslySetInnerHTML={{
                __html: t("home.boxTwelve.content"),
              }}
            />
          </div>
        </ContentRowComponent>
        <SeperatorComponent
          background=" #1B1B2B"
          height={140}
          hideBelow={1201}
        />
        <ContentRowComponent className="center-items" maxWidthWrapper>
          <div className="center-text">
            <div className="center-text">
              <SeperatorComponent height={100} mobileOnly />
              <h2
                className="text-wrapper--h2"
                dangerouslySetInnerHTML={{
                  __html: t("home.boxThirteen.title"),
                }}
              />
              <SeperatorComponent height={20} />
              <div
                className="text-wrapper--paragraph dna-padding"
                dangerouslySetInnerHTML={{
                  __html: t("home.boxThirteen.content"),
                }}
              />
            </div>
            <div className="maxed-img-dna">
              <StaticImage
                className="center-items"
                src="../assets/images/dna.png"
                alt="MedBed Front"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
            <SeperatorComponent height={100} mobileOnly />
          </div>
        </ContentRowComponent>
        <SeperatorComponent
          background=" #1B1B2B"
          height={140}
          hideBelow={1201}
        />
        <ContentRowComponent
          maxWidthWrapper
          background=" #1B1B2B"
          itemConfig={[
            { background: "transparent", width: 50 },
            { background: "#eeeded", width: 50 },
          ]}
        >
          <StaticImage
            className="center-items full-height"
            src="../assets/images/bed_closed.jpeg"
            alt="MedBed Front"
            placeholder="blurred"
          />
          <div className="padding-wrapper-120 brown-max-1201 test-medbed-padding">
            <h2
              className="text-wrapper--h2"
              dangerouslySetInnerHTML={{
                __html: t("home.boxFourteen.title"),
              }}
            />
            <SeperatorComponent height={20} />
            <div
              className="text-wrapper--paragraph"
              dangerouslySetInnerHTML={{
                __html: t("home.boxFourteen.content"),
              }}
            />
          </div>
        </ContentRowComponent>
        <SeperatorComponent
          background=" #1B1B2B"
          height={140}
          hideBelow={1201}
        />
        <ContentRowComponent
          background=" #1B1B2B"
          itemConfig={[{ background: "#eeeded", width: 50 }, { width: 50 }]}
          maxWidthWrapper
          order={{ mobile: [2, 1] }}
        >
          <div
            className="padding-wrapper-120 test-medbed-padding"
            style={{ paddingTop: "75px", paddingBottom: "75px" }}
          >
            <h2
              className="text-wrapper--h2"
              dangerouslySetInnerHTML={{
                __html: t("home.boxFifteen.title"),
              }}
            />
            <SeperatorComponent height={20} />
            <div
              className="text-wrapper--paragraph"
              dangerouslySetInnerHTML={{
                __html: t("home.boxFifteen.content"),
              }}
            />
          </div>
          <StaticImage
            className="center-items full-height"
            src="../assets/images/mobile_medbed.jpg"
            alt="MedBed Front"
            placeholder="blurred"
          />
        </ContentRowComponent>
        <SeperatorComponent background=" #1B1B2B" height={80} />
        <ContentRowComponent
          noMinHeight
          className="center-items"
          background=" #1B1B2B"
          maxWidthWrapper
        >
          <div className="center-text maxed-50 custom-tweak-button-wrapper">
            <ButtonComponent
              className="custom-voice-button"
              backgroud="#F89B06"
              url={t("home.testButton.link", {
                replace: { inviteCode: inviteCode },
              })}
              type="link"
              title={t("home.testButton.text")}
            />
          </div>
        </ContentRowComponent>
        <SeperatorComponent background=" #1B1B2B" height={80} />
        <YouTubeComponent />
        <SeperatorComponent background=" #1B1B2B" height={100} />
        {t("home.calendlyLink", { defaultValue: "" }) === "" ? (
          <></>
        ) : (
          <>
            <SeperatorComponent height={3} />
            <ContentRowComponent
              noMinHeight
              className="center-items calendly-wrapper"
            >
              <a
                id="support"
                href={t("home.calendlyLink")}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="center-items support-image"
                  alt="Calendly"
                  src={generateCalendlyImage()}
                />
              </a>
            </ContentRowComponent>
            <SeperatorComponent height={1} />
          </>
        )}
        <FeedbackComponent />
        <SeperatorComponent background="#1B1B2B" height={40} />
        <FaqComponent />
        <SeperatorComponent background=" #1B1B2B" height={40} />
        <ContentRowComponent
          className="center-items padding-wrapper-60"
          itemClassName="full-width"
          background=" #1B1B2B"
          maxWidthWrapper
        >
          <div className="max-width-wrapper">
            <h2
              className="text-wrapper--h2 white"
              dangerouslySetInnerHTML={{
                __html: t("home.disclaimer.title"),
              }}
            />
            <SeperatorComponent />
            <div
              className="text-wrapper--paragraph white"
              dangerouslySetInnerHTML={{
                __html: t("home.disclaimer.content"),
              }}
            />
          </div>
        </ContentRowComponent>
        <SeperatorComponent background=" #1B1B2B" height={80} />
      </LayoutComponent>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
